import { fetchLimitConfigs } from 'LossLimits/api';
import StakeLimitsBreachModal from '../stake_limits_breach_modal/components/stake_limits_breach_modal';
import { fetchLossLimits } from './api';

const LossLimitsBreachModalApp = (props) => (
  <StakeLimitsBreachModal
    fetchLimitsEndpoint={fetchLossLimits}
    fetchLimitConfigs={fetchLimitConfigs}
    {...props}
  />
);

export default LossLimitsBreachModalApp;
